import '../styles/nprogress.css';
import '../styles/archives.css'; // This is for demonstration purposes and should not be used for production styles
import React from 'react';
import NProgress from 'nprogress';
import Router from 'next/router';
import {AppProps} from 'next/app';
import {SessionProvider} from '@/components/SessionProvider';
import {GoogleAnalytics} from '@/components/GoogleAnalytics';
import {JsonLd} from '@/components/JsonLd';
import {UserAuthorizationsProvider} from '@icpsr/auth/lib/esm';

Router.events.on('routeChangeStart', () => {
  NProgress.start();
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

export default function App({Component, pageProps}: AppProps) {
  return (
    <SessionProvider session={pageProps.session}>
      <UserAuthorizationsProvider>
        <GoogleAnalytics />
        {pageProps?.study?.series?.jsonld &&
          pageProps?.study?.series?.jsonld !== null && (
            <JsonLd json={pageProps.study.series.jsonld} />
          )}

        <Component {...pageProps} />
      </UserAuthorizationsProvider>
    </SessionProvider>
  );
}
