import getConfig from 'next/config';
import {
  SessionProvider as NextAuthSessionProvider,
  SessionProviderProps,
  useSession,
  signIn,
  SessionContextValue,
  SignInOptions,
} from 'next-auth/react';
import React, {useEffect, useState} from 'react';
import {NextRouter, useRouter} from 'next/router';
import {OAuthProviderType} from 'next-auth/providers';

/**
 * Wraps the children with the NextAuth session provider, if the new auth flag is enabled.
 * If not, returns only the children.
 * @param children - The contents wrapepd by this session provider
 * @returns A JSX.Element containing the children
 */
export function SessionProvider(props: SessionProviderProps) {
  const config = getConfig();
  const enableNewAuth = JSON.parse(
    config.publicRuntimeConfig.featureFlags.enableNewAuth as string
  );
  const fullBasePath = `${config.publicRuntimeConfig.basePath}${'/api/auth'}`;
  if (!enableNewAuth) return <>{props.children}</>;
  return (
    <NextAuthSessionProvider session={props.session} basePath={fullBasePath}>
      {props.children}
    </NextAuthSessionProvider>
  );
}

export function AuthenticateUser(children: JSX.Element) {
  const nextConfig = getConfig();
  const isNewAuthEnabled = JSON.parse(
    nextConfig.publicRuntimeConfig.featureFlags.enableNewAuth as string
  );
  if (isNewAuthEnabled) {
    const router: NextRouter = useRouter();
    const {data: session, status: loginStatus}: SessionContextValue =
      useSession();
    const [userProfileFetchSuccess, setUserProfileFetchSuccess] =
      useState(false);
    useEffect(() => {
      if (loginStatus === 'unauthenticated') {
        signIn(
          nextConfig.publicRuntimeConfig
            .nextAuthKeycloakProviderId as OAuthProviderType,
          {
            callbackUrl: (router.basePath + router.asPath) as string,
          } as SignInOptions
        );
      } else if (loginStatus === 'authenticated') {
        if (
          session &&
          session.user &&
          session.user.email !== null &&
          session.user.name !== null
        ) {
          setUserProfileFetchSuccess(true);
        } else {
          console.log('loginStatus:', loginStatus);
          router.push('/error/500');
        }
      }
    }, [session]);

    return (
      <>
        {loginStatus === 'loading' && <></>}
        {loginStatus === 'unauthenticated' && (
          <>
            <p className="text-center">Redirecting...</p>
          </>
        )}
        {loginStatus === 'authenticated' && userProfileFetchSuccess && (
          <>{children}</>
        )}
      </>
    );
  } else if (!isNewAuthEnabled) {
    return <>{children}</>;
  }
}
