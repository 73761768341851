import {JsonldProps} from '@/lib/types/study';

const updateJsonLd = (jsonld: JsonldProps) => {
  const updatedJson = {...jsonld};
  if (jsonld?.identifier !== undefined) {
    updatedJson['@id'] = jsonld.identifier;
    delete updatedJson.identifier;
  }
  if (jsonld?.creator !== undefined) {
    updatedJson['author'] = jsonld.creator;
    delete updatedJson.creator;
  }

  return updatedJson;
};

export function JsonLd(props: {readonly json: JsonldProps}) {
  return (
    <script
      data-testid="ld+json"
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(updateJsonLd(props.json)),
      }}
    />
  );
}
