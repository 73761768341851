import {useGoogleAnalytics} from '@/lib/hooks/useGoogleAnalytics';
import Script from 'next/script';
import getNextConfig from 'next/config';

/**
 * Handles loading Google Analytics into Archive-UI.
 * TODO - This has the potential to be slow. NextJS currently has a @next/third-party
 * library that we could potentially use for Google Analytics in the future with Next@14+
 * Source: https://stackoverflow.com/a/76250406
 */
export function GoogleAnalytics() {
  useGoogleAnalytics();

  const {googleTagManagerId} = getNextConfig().publicRuntimeConfig;
  if (!googleTagManagerId) return <></>;

  return (
    <>
      <Script
        id="google-tag-manager"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${googleTagManagerId}');`,
        }}
      />
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${googleTagManagerId}`}
          height="0"
          width="0"
          style={{display: 'none', visibility: 'hidden'}}
        />
      </noscript>
    </>
  );
}
