import {useEffect} from 'react';
import {GaOptions, InitOptions} from 'react-ga4/types/ga4';
import ReactGA from 'react-ga4';
import getNextConfig from 'next/config';

interface ReactGAOptions {
  nonce?: string;
  testMode?: boolean;
  gtagUrl?: string;
  gaOptions?: GaOptions | unknown;
  gtagOptions?: unknown;
}

/**
 * Injects Google Analytics into the page. This should be called on the _app level
 */
export function useGoogleAnalytics() {
  // Firefox will soon begin marking cookies without a SameSite value as Lax, blocking
  // third-party cookies. We need to set 'SameSite=None;Secure' to ensure that analytics still
  // functions correctly in these cases.
  // More Info: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie#samesitesamesite-value
  const options: ReactGAOptions = {
    gaOptions: {cookieFlags: 'SameSite=None;Secure'},
  };

  useEffect(() => {
    const {analyticsKey, downloadAnalyticsKey} = getNextConfig()
      .publicRuntimeConfig as Record<string, string>;

    const trackingIds: (string | undefined)[] = [analyticsKey];

    trackingIds.push(downloadAnalyticsKey);

    const initOptions: InitOptions[] = trackingIds
      .filter((trackingId: string | undefined) => trackingId)
      .map((trackingId: string) => ({...options, trackingId}));

    if (initOptions.length === 0) {
      console.warn('No keys are present for Google Analytics. Skipping...');
      return;
    }

    ReactGA.initialize(initOptions);
  }, []);
}
